

function TimeSlotdropdown() {
    return [
      { TimeSlotId : 1, TimeSlot: "1" },
      { TimeSlotId: 2, TimeSlot: "2" },
      { TimeSlotId: 3, TimeSlot: "3" },
      { TimeSlotId: 4, TimeSlot: "4" },
      { TimeSlotId: 5, TimeSlot: "5" },
      { TimeSlotId: 6, TimeSlot: "6" },
      { TimeSlotId: 7, TimeSlot: "7" },
      { TimeSlotId: 8, TimeSlot: "8" },
      { TimeSlotId: 9, TimeSlot: "9" },
      { TimeSlotId: 10, TimeSlot: "10" },
      { TimeSlotId: 11, TimeSlot: "11" },
      { TimeSlotId: 12, TimeSlot: "12" },
    ];
  }
  
  export default TimeSlotdropdown; 