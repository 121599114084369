import React, { Component } from "react";
import { Route } from "react-router-dom";
// import Loadable from "react-loadable";
import StoreLayout from "./../StoreManager/storelayout";
import StoreDashboard from "./StoreDashboard";
import StoreTask from "./StoreTask";
import EditStoreTask from "./EditStoreTask";
import StoreTaskByTicket from "./StoreTaskByTicket";
import RaiseClaim from "./RaiseClaim";
import Claim from "./Claim";
import ClaimApproveReject from "./ClaimApproveReject";
import StoreAddTask from "./StoreAddTask";
import Settings from "./../Settings/Settings";
import HierarchyMaster from "./../Settings/Store/HierarchyMaster";
import StorePriority from "./../Settings/Store/StorePriority";
import StoreCRMRole from "./../Settings/Store/StoreCRMRole";
import StoreReports from "./../Settings/Store/StoreReports";
import ItemMaster from "./../Settings/Store/ItemMaster";
import SlaTemplateDepartment from "./../Settings/Store/SlaTemplateDepartment";
import StoreUsers from "./../Settings/Store/StoreUsers";
import StoreModule from "./../Settings/Store/StoreModule";
import DepartmentMaster from "./../Settings/Store/DepartmentMaster";
import ClaimCategoryMaster from "./../Settings/Store/ClaimCategoryMaster";
import StoreAlerts from "./../Settings/Store/StoreAlerts";
import { NotificationContainer } from "react-notifications";
import StoreFileUploadLogs from "../Settings/Store/StoreFileUploadLogs";
import UserProfile from "./UserProfile";
import Appointment from "./Appointment";
import StoreCampaign from "../Campaign/StoreCampaign";
import HomeShopSetting from "./../Settings/Store/HomeShopSetting";
import ChatSettings from "./../Settings/Store/ChatSettings";
import CardAssets from "./../Settings/Store/CardAssets";
import OrderSetting from "./../Settings/Store/OrderSetting";
import storeMyTicket from "./storeMyTicket";
import storeMyTicketList from "./storeMyTicketList";
import Orders from "./Orders";

const NotFound = () => {
  return (
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h1>
            4<span>0</span>4
          </h1>
        </div>
        <h2>the page you requested could not found</h2>
      </div>
    </div>
  );
};
// const StoreDashboard = Loadable({
//   loader: () => import("./StoreDashboard"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreTask = Loadable({
//   loader: () => import("./StoreTask"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const storeMyTicket = Loadable({
//   loader: () => import("./storeMyTicket"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const storeMyTicketList = Loadable({
//   loader: () => import("./storeMyTicketList"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const EditStoreTask = Loadable({
//   loader: () => import("./EditStoreTask"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreTaskByTicket = Loadable({
//   loader: () => import("./StoreTaskByTicket"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const RaiseClaim = Loadable({
//   loader: () => import("./RaiseClaim"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const Claim = Loadable({
//   loader: () => import("./Claim"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const ClaimApproveReject = Loadable({
//   loader: () => import("./ClaimApproveReject"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreAddTask = Loadable({
//   loader: () => import("./StoreAddTask"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const HierarchyMaster = Loadable({
//   loader: () => import("./../Settings/Store/HierarchyMaster"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StorePriority = Loadable({
//   loader: () => import("./../Settings/Store/StorePriority"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreCRMRole = Loadable({
//   loader: () => import("./../Settings/Store/StoreCRMRole"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const Settings = Loadable({
//   loader: () => import("./../Settings/Settings"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreReports = Loadable({
//   loader: () => import("./../Settings/Store/StoreReports"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const ItemMaster = Loadable({
//   loader: () => import("./../Settings/Store/ItemMaster"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const SlaTemplateDepartment = Loadable({
//   loader: () => import("./../Settings/Store/SlaTemplateDepartment"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreUsers = Loadable({
//   loader: () => import("./../Settings/Store/StoreUsers"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreModule = Loadable({
//   loader: () => import("./../Settings/Store/StoreModule"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const DepartmentMaster = Loadable({
//   loader: () => import("./../Settings/Store/DepartmentMaster"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const ClaimCategoryMaster = Loadable({
//   loader: () => import("./../Settings/Store/ClaimCategoryMaster"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreAlerts = Loadable({
//   loader: () => import("./../Settings/Store/StoreAlerts"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreFileUploadLogs = Loadable({
//   loader: () => import("../Settings/Store/StoreFileUploadLogs"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const UserProfile = Loadable({
//   loader: () => import("./UserProfile"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const Appointment = Loadable({
//   loader: () => import("./Appointment"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreCampaign = Loadable({
//   loader: () => import("../Campaign/StoreCampaign"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const HomeShopSetting = Loadable({
//   loader: () => import("./../Settings/Store/HomeShopSetting"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const ChatSettings = Loadable({
//   loader: () => import("./../Settings/Store/ChatSettings"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const CardAssets = Loadable({
//   loader: () => import("./../Settings/Store/CardAssets"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const OrderSetting = Loadable({
//   loader: () => import("./../Settings/Store/OrderSetting"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const Orders = Loadable({
//   loader: () => import("./Orders"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreLayout = Loadable({
//   loader: () => import("./../StoreManager/storelayout"),
//   loading: () => <div className="loader-icon"></div>,
// });

export class StoreApp extends Component {
  // componentDidMount() {
  //   debugger;
  //   var isPathActive = window.location.pathname.split("/")[2];
  //   var moduleData = JSON.parse(window.localStorage.getItem("module"));
  //   if (moduleData) {
  //     var isActiveModuel = [];
  //     if (isPathActive) {
  //       isActiveModuel = moduleData.filter(
  //         (x) => x.moduleName.toLowerCase() === isPathActive.toLowerCase()
  //       );
  //     }
  //     if (isActiveModuel.length === 0) {
  //       this.props.history.push("/store/404notfound");
  //     }
  //   }
  // }
  render() {
    const { match } = this.props;
    return (
      <StoreLayout>
        <Route
          exact
          path={`${match.url}/storeDashboard`}
          component={StoreDashboard}
        />
        <Route exact path={`${match.url}/storetask`} component={StoreTask} />
        <Route exact path={`${match.url}/myTicket`} component={storeMyTicket} />
        <Route
          exact
          path={`${match.url}/myTicketList`}
          component={storeMyTicketList}
        />

        <Route
          exact
          path={`${match.url}/editStoreTask`}
          component={EditStoreTask}
        />
        <Route
          exact
          path={`${match.url}/storeTaskByTicket`}
          component={StoreTaskByTicket}
        />
        <Route exact path={`${match.url}/raiseClaim`} component={RaiseClaim} />
        <Route exact path={`${match.url}/claim`} component={Claim} />
        <Route
          exact
          path={`${match.url}/claimApproveReject`}
          component={ClaimApproveReject}
        />
        <Route
          exact
          path={`${match.url}/storeAddTask`}
          component={StoreAddTask}
        />
        <Route
          exact
          path={`${match.url}/hierarchyMaster`}
          component={HierarchyMaster}
        />
        <Route
          exact
          path={`${match.url}/storePriority`}
          component={StorePriority}
        />
        <Route
          exact
          path={`${match.url}/storeCRMRole`}
          component={StoreCRMRole}
        />
        <Route exact path={`${match.url}/settings`} component={Settings} />
        <Route
          exact
          path={`${match.url}/storeReports`}
          component={StoreReports}
        />
        <Route exact path={`${match.url}/itemMaster`} component={ItemMaster} />
        <Route
          exact
          path={`${match.url}/slaTemplateDepartment`}
          component={SlaTemplateDepartment}
        />
        <Route exact path={`${match.url}/storeUsers`} component={StoreUsers} />
        <Route
          exact
          path={`${match.url}/storeModule`}
          component={StoreModule}
        />
        <Route
          exact
          path={`${match.url}/departmentMaster`}
          component={DepartmentMaster}
        />
        <Route
          exact
          path={`${match.url}/claimCategoryMaster`}
          component={ClaimCategoryMaster}
        />
        <Route
          exact
          path={`${match.url}/storeAlerts`}
          component={StoreAlerts}
        />
        <Route
          exact
          path={`${match.url}/storeFileUploadLogs`}
          component={StoreFileUploadLogs}
        />
        <Route
          exact
          path={`${match.url}/userProfile`}
          component={UserProfile}
        />
        <Route
          exact
          path={`${match.url}/appointment`}
          component={Appointment}
        />
        <Route exact path={`${match.url}/campaign`} component={StoreCampaign} />
        <Route
          exact
          path={`${match.url}/homeshopsetting`}
          component={HomeShopSetting}
        />
        <Route
          exact
          path={`${match.url}/chatsettings`}
          component={ChatSettings}
        />
        <Route exact path={`${match.url}/cardassets`} component={CardAssets} />
        <Route
          exact
          path={`${match.url}/ordersetting`}
          component={OrderSetting}
        />
        <Route exact path={`${match.url}/orders`} component={Orders} />
        {/* <Route exact path={`${match.url}/404notfound`} component={NotFound} /> */}

        <NotificationContainer />
      </StoreLayout>
    );
  }
}
export default StoreApp;
