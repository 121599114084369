import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
// import Loadable from "react-loadable";
import "react-notifications/lib/notifications.css";

//Css
import "./assets/css/style.css";
import "./assets/css/settings.css";
import "./assets/css/store.css";
import "./assets/css/custome.css";

//JS
import "./../node_modules/popper.js/dist/popper.js";
import "./../node_modules/bootstrap/dist/js/bootstrap.js";
import "./../node_modules/jquery/dist/jquery.js";
import "./assets/css/chat.css";
import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-pagination-js/dist/styles.css";

/////js
import "./../node_modules/bootstrap/dist/js/bootstrap.min.js";

///Component
import SignIn from "./Component/SignIn";
import ForgotPassword from "./Component/ForgotPassword";
import ProgramCodeSignIn from "./Component/ProgramCodeSignIn";
import App from "./routes/index";
import PieChart from "./Component/PieChart/PieChart";
import StoreApp from "./routes/StoreManager/index";
import ChangePassword from "./Component/ChangePassword";
import UserForgotPassword from "./Component/UserForgotPassword";
import StoreProgramCode from "./Component/Store/StoreProgramCode";
import StoreSignIn from "./Component/Store/StoreSignIn";
import StoreForgotPassword from "./Component/Store/StoreForgotPassword";
import StoreUserForgotPassword from "./Component/Store/StoreUserForgotPassword";
import ModuleSelect from "./Component/ModuleSelect";
import StoreChangePassword from "./Component/Store/ChangePassword";
import LanguageSelection from "./Component/Store/LanguageSelection";
import TicketingLanguageSelection from "./Component/TicketingLanguageSelection";
import ShipmentPrintHtml from "./routes/StoreManager/OrderTabs/ShipmentPrintHtml";

// const App = Loadable({
//   loader: () => import("./routes/index"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreApp = Loadable({
//   loader: () => import("./routes/StoreManager/index"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const ProgramCodeSignIn = Loadable({
//   loader: () => import("./Component/ProgramCodeSignIn"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const SignIn = Loadable({
//   loader: () => import("./Component/SignIn"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const TicketingLanguageSelection = Loadable({
//   loader: () => import("./Component/TicketingLanguageSelection"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const ForgotPassword = Loadable({
//   loader: () => import("./Component/ForgotPassword"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const ChangePassword = Loadable({
//   loader: () => import("./Component/ChangePassword"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreChangePassword = Loadable({
//   loader: () => import("./Component/Store/ChangePassword"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const LanguageSelection = Loadable({
//   loader: () => import("./Component/Store/LanguageSelection"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreProgramCode = Loadable({
//   loader: () => import("./Component/Store/StoreProgramCode"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreSignIn = Loadable({
//   loader: () => import("./Component/Store/StoreSignIn"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreForgotPassword = Loadable({
//   loader: () => import("./Component/Store/StoreForgotPassword"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const StoreUserForgotPassword = Loadable({
//   loader: () => import("./Component/Store/StoreUserForgotPassword"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const UserForgotPassword = Loadable({
//   loader: () => import("./Component/UserForgotPassword"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const PieChart = Loadable({
//   loader: () => import("./Component/PieChart/PieChart"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const ShipmentPrintHtml = Loadable({
//   loader: () => import("./routes/StoreManager/OrderTabs/ShipmentPrintHtml"),
//   loading: () => <div className="loader-icon"></div>,
// });
// const ModuleSelect = Loadable({
//   loader: () => import("./Component/ModuleSelect"),
//   loading: () => <div className="loader-icon"></div>,
// });

ReactDOM.render(
  <Router>
    {/* if required the only on uncomment this code and your system do not push this line*/}
    {/* <Route exact path="/" component={ModuleSelect} /> */}
    <Route path="/admin" component={App} />
    <Route path="/store" component={StoreApp} />
    <Route exact path="/programCodeSignIn" component={ProgramCodeSignIn} />
    <Route exact path="/SignIn" component={SignIn} />
    <Route
      exact
      path="/TicketingLanguageSelection"
      component={TicketingLanguageSelection}
    />
    <Route exact path="/Forgotpassword" component={ForgotPassword} />
    <Route exact path="/ChangePassword" component={ChangePassword} />
    <Route exact path="/storeChangePassword" component={StoreChangePassword} />
    <Route exact path="/languageSelection" component={LanguageSelection} />
    <Route exact path="/" component={StoreProgramCode} />
    <Route exact path="/storeSignIn" component={StoreSignIn} />
    <Route exact path="/storeForgotpassword" component={StoreForgotPassword} />
    <Route
      exact
      path="/storeUserForgotPassword"
      component={StoreUserForgotPassword}
    />
    <Route exact path="/UserForgotPassword" component={UserForgotPassword} />
    <Route exact path="/PieChart" component={PieChart} />
    <Route path="/ShipmentPrintHtml" component={ShipmentPrintHtml} />
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
