import React, { Component } from "react";

class ShipmentPrintHtml extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderCustDetails: {},
      orderLabelDetails: {},
      orderLabelItemsDetails: [],
      orderTemplateDetails: {},
      orderTotalPrice: "",
    };
  }
  componentDidMount() {
    var OrderShipmentData = JSON.parse(
      window.localStorage.getItem("OrderShipment")
    );
    if (OrderShipmentData !== "" && OrderShipmentData !== null) {
      setTimeout(() => {
        this.setState({
          orderCustDetails: OrderShipmentData.orderCustDetails,
          orderLabelDetails: OrderShipmentData.orderLabelDetails,
          orderLabelItemsDetails: OrderShipmentData.orderLabelItemsDetails,
          orderTemplateDetails: OrderShipmentData.orderTemplateDetails,
          orderTotalPrice: OrderShipmentData.totalPrice,
        });
      }, 200);
    } else {
      this.setState({
        orderCustDetails: {},
        orderLabelDetails: {},
        orderLabelItemsDetails: [],
        orderTemplateDetails: {},
        orderTotalPrice: "",
      });
    }
    window.localStorage.removeItem("OrderShipment");
  }
  render() {
    return (
      <div className="row ordWidth">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="ordMainhtml">
            <div className="ord2tbl">
              <div className="ordRow">
                <label className="ordShipdt">
                  Date:
                  <p className="ordMl">
                    {this.state.orderCustDetails.orderCreatedOn}
                  </p>
                </label>
              </div>
              <div className="row ordMtb">
                <div className="col-md-6">
                  <label>Deliver To</label>
                </div>
                <div className="col-md-6">
                  <label>Shipped By (If undelivered, return to)</label>
                </div>
              </div>
              <div className="row ordMtb">
                <div className="col-md-6">
                  <label>{this.state.orderCustDetails.customerName}</label>
                </div>
                <div className="col-md-6">
                  <label>{this.state.orderCustDetails.storeName}</label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="ordShipLabel">
                    {this.state.orderCustDetails.shippingAddress}
                  </label>
                  <label className="ordShipLabel">
                    {this.state.orderCustDetails.city}
                  </label>
                  <label className="ordShipLabel">
                    {this.state.orderCustDetails.state},
                    {this.state.orderCustDetails.pinCode},
                  </label>
                  <label className="ordShipLabel">
                    {this.state.orderCustDetails.country}
                  </label>
                  <label className="ordShipLabel">
                    lat: <p>{this.state.orderCustDetails.latitude}</p>
                  </label>
                  <label className="ordShipLabel">
                    long: <p>{this.state.orderCustDetails.longitude}</p>
                  </label>
                  <label className="ordShipLabel">
                    Mobile No: <p>{this.state.orderCustDetails.mobileNumber}</p>
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="ordLeftMb ordShipLabel">
                    {this.state.orderCustDetails.address}
                  </label>
                  <label className="ordLeftMb ordShipLabel">
                    {this.state.orderCustDetails.cityName}
                  </label>
                  <label className="ordLeftMb ordShipLabel">
                    {this.state.orderCustDetails.stateName},
                    {this.state.orderCustDetails.pincodeID}
                  </label>
                  <label className="ordLeftMb ordShipLabel">
                    {this.state.orderCustDetails.countryName}
                  </label>
                  <label>
                    Mobile No: {this.state.orderCustDetails.storePhoneNo}
                  </label>
                </div>
              </div>
            </div>
            <div className="ord3tbl">
              <label>
                Order Id: <p>{this.state.orderLabelDetails.orderID}</p>
              </label>
              <label>
                Request Id: <p>{this.state.orderLabelDetails.requestID}</p>
              </label>
            </div>
            <div className="ord3tbl">
              <label className="ordFw">PREPAID</label>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Shipment Weight:
                    <p>{this.state.orderTemplateDetails.weight}</p>
                  </label>
                </div>
                <div className="col-md-6">
                  <label>
                    Dimensions:
                    <p>{this.state.orderTemplateDetails.dimensions}</p>
                  </label>
                </div>
              </div>
            </div>
            <div className="ord4tbl">
              <table className="table-bordered">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th style={{ width: "375px" }}>Item</th>
                    <th>QTY </th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.orderLabelItemsDetails !== null &&
                    this.state.orderLabelItemsDetails.map((item, i) => (
                      <tr key={i}>
                        <td>{item.sku}</td>
                        <td>{item.itemName}</td>
                        <td>{item.quantity}</td>
                        <td>{item.itemPrice}</td>
                      </tr>
                    ))}
                  <tr>
                    <td colSpan="2"></td>
                    <td align="right">Total </td>
                    <td align="center">
                      {this.state.orderTotalPrice}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    );
  }
}

export default ShipmentPrintHtml;
